<template>
  <v-card class="ma-4" height="285">
    <div style="background-color: rgb(219, 219, 219);">
      <v-img
        v-if="!isError"
        v-ripple
        :src="url"
        style="cursor: pointer;"
        class="white--text align-end"
        height="205"
        position="center"
        @click="showImage"
        @error="isError = true"
      />
      <v-img
        v-else
        contain
        src="/img/empty-media.svg"
        class="white--text align-end"
        height="205"
        position="center"
      />
    </div>
    <v-card-actions>
      <div class="d-flex flex-column w-100">
        <div class="subtitle-1 px-2 text-ellipsis" v-text="title" />
        <div class="subtitle-1 px-2 text--secondary" v-text="subTitle" />
      </div>
      <v-spacer />
      <dropdown-menu
        v-if="actions.length > 0"
        :items="actions"
        offset-y
        @input="$emit('click:action', $event)"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api';
import { usePopup } from '@/compositions/popup';

export default {
  name: 'MediaCard',
  props: {
    title: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    mediaType: {
      type: String,
      default: ''
    },
    actions: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const isError = ref(false);
    const popup = usePopup();

    const showImage = () => {
      const isImage = props.mediaType.includes('image');
      if (isImage) {
        popup.open({
          component: () => import('./PhotoView'),
          attrs: {
            width: 'auto',
            'min-width': '500px',
            'min-height': '500px'
          },
          props: {
            imageUrl: props.url
          }
        });
      }
    };
    return {
      isError,
      showImage
    };
  }
};
</script>

<style lang="scss" scoped>
.w-100 {
  max-width: 100%;
}
</style>
